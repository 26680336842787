import React from 'react';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import SectionDark from '../shared/SectionDark';
import TextBlock from '../shared/TextBlock';

import respond from '../../styles/mediaQueries';

const StyledHeader = styled('div')` 
    margin-bottom: 12rem;

	${respond('bp4')} {
		margin-bottom: 8rem;
	}
`;

const Grid = styled('div')` 
	display: grid;
	grid-template-columns: minmax(min-content, 44.8rem) 1fr; 
	
	${respond('bp1')} {
		grid-template-columns: 1fr 1fr;
	}

	${respond('bp4')} {
		grid-template-columns: 1fr;
		margin: auto;
	}
`;

const ImageContainer = styled('div')`
	margin-left: 8rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-height: 44rem;
	padding-bottom: 4rem;

	${respond('bp1')} {
		margin-left: 4rem;
	}

	${respond('bp4')} {
		margin-left: 0;
		grid-row: 3 / 4;
	}

	& img {
		width: 100%;
		height: 40rem;
		margin-bottom: 4rem;

		${respond('bp4')} {
			margin-top: 4rem;
		}
	}
	
	& h4 {
		text-align: center;
		margin-bottom: 2.4rem;
	}	
`;

const Heading = styled('h1')`
	grid-column: 1 / end;
	grid-row: 1 / 2;
`;

const Links = styled('div')` 
	display: flex;
	justify-content: flex-end;
	border-top: .3rem solid var(--textInverse2);
	padding-top: .8rem;
	margin-top: 4rem;

	& a {
		margin-left: 2.4rem;
	}
`;

const AboutHeader = () => {
	const imageData = useStaticQuery(imageQuery);

	return (
		<StyledHeader>
			<SectionDark>
				<Grid>
					<Heading>About</Heading>
					<TextBlock>
						<p>
							I’m a web developer currently working in the
							JavaScript ecosystem, particularly React, Express,
							and Node, along with the help of a MongoDB or
							PostgreSQL database here and there. I have a passion
							for creating apps anchored by solid visual design
							and code quality principles, no one likes junk! I
							constantly work to improve my process, integrate new
							trends, and explore technology with the goal of
							building stuff that makes the world a bit better for
							others.
						</p>
						<p>
							I love a good challenge and learning new things. My
							brain thrives on exploring the intersection of art
							and science, sarcastic humor, and pondering the
							complexities of the human condition. Also coffee,
							lots and lots of coffee!
							<span role='img' aria-label='coffee emoji'>
								{' '}
								☕
							</span>
							<span role='img' aria-label='coffee emoji'>
								{' '}
								☕
							</span>
							<span role='img' aria-label='coffee emoji'>
								{' '}
								☕
							</span>
						</p>
					</TextBlock>
					<ImageContainer>
						<Img
							fluid={imageData.headshot.childImageSharp.fluid}
							alt='Photo of Brandon Corey'
							imgStyle={{
								objectFit: 'contain',
								maxHeight: 'auto',
								display: 'block'
							}}
							style={{ maxHeight: '100%' }}
						/>

						<Links>
							<a href='https://www.github.com/bcorey85'>Github</a>
						</Links>
					</ImageContainer>
				</Grid>
			</SectionDark>
		</StyledHeader>
	);
};

const imageQuery = graphql`
	query HeadshotQuery {
		headshot: file(relativePath: { eq: "headshot-bw.jpg" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;

export default AboutHeader;

import React from 'react';

import SectionLight from '../shared/SectionLight';
import AboutSkills from './AboutSkills';
import AboutValues from './AboutValues';

const AboutContent = () => {
	return (
		<SectionLight>
			<AboutSkills />
			<AboutValues />
		</SectionLight>
	);
};

export default AboutContent;

import React from 'react';

import LayoutTextImage from '../shared/LayoutTextImage';
import LayoutTextImageHeader from '../shared/LayoutTextImageHeader';
import TextBlock from '../shared/TextBlock';

const AboutValues = () => {
	return (
		<React.Fragment>
			<LayoutTextImageHeader color={'var(--text2)'} reverse>
				Values
			</LayoutTextImageHeader>
			<LayoutTextImage reverse>
				<div />
				<TextBlock>
					<h6>Act With Intention</h6>
					<p>Focus on &lsquo;why&rsquo;</p>
					<h6>Constantly Improve</h6>
					<p>Grow to live &mdash; live to grow</p>
					<h6>Make Cool Stuff </h6>
					<p>Life is boring without creating</p>
					<h6>Be Humble</h6>
					<p>Treat people with respect above all else</p>
					<h6>Drink Less Coffee</h6>
					<p>
						I might have a problem{' '}
						<span role='img' aria-label='coffee emoji'>
							☕
						</span>
					</p>
				</TextBlock>
			</LayoutTextImage>
		</React.Fragment>
	);
};

export default AboutValues;

import React from 'react';

import SEO from '../components/Layout/SEO';
import Layout from '../components/Layout/Layout';
import AboutHeader from '../components/AboutPage/AboutHeader';
import AboutContent from '../components/AboutPage/AboutContent';

const Contact = ({ location }) => {
	return (
		<Layout location={location}>
			<SEO title='About' />
			<AboutHeader />
			<AboutContent />
		</Layout>
	);
};

export default Contact;

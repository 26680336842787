import React from 'react';
import styled from '@emotion/styled';

import Arrow from '../svg/icons/Arrow';

import respond from '../../styles/mediaQueries';

const StyledHeadingArrow = styled('header')`  
    display: block;
    width: 100%;

    & h3 {
        position: relative;
        height: auto;
        width: 100%;

        ${respond('bp4')} {
            margin-bottom: 0; 
            width: 90%
        }
    }

    ${respond('bp4')} {
        border-bottom: .3rem solid var(--text);
        margin-bottom: 2.4rem;
    }
`;

const ArrowContainer = styled(`span`)`
    position: absolute;
    left: -4.4rem;
    bottom: 0;
    width: 2.4rem;


    ${respond('bp2')} {
        width: 2rem;
        left: -3.6rem
    }


    ${respond('bp4')} {
        left: auto;
        right: -10%;
        bottom: auto;
        transform: scaleX(-1);
    }

    & svg {
        width: 100%;
    }
`;

const HeadingArrow = ({ color, children }) => {
	return (
		<StyledHeadingArrow>
			<h3>
				{children}
				<ArrowContainer>
					<Arrow fill={color || 'var(--text)'} />
				</ArrowContainer>
			</h3>
		</StyledHeadingArrow>
	);
};

export default HeadingArrow;

import React from 'react';
import styled from '@emotion/styled';

import respond from '../../styles/mediaQueries';

const StyledLayout = styled('div')`
    display: grid;
    grid-template-columns:  44.8rem 1fr;
    grid-gap: 2.4rem;
    width: 100%;
    margin-bottom: 8rem;

    ${respond('bp1')} {
        grid-template-columns:  42rem 1fr;
    }

    
	${respond('bp4')} {
		display: flex;
        flex-direction: column-reverse;

	}

    & > div:last-of-type {
        border-top: .3rem solid var(--text2);
        margin-top: 1.2rem;
        margin-bottom: 2.4rem;

        ${respond('bp4')} {
            border-top: none;
            margin-bottom: 0rem;
            margin-top: 0;
        }
    }    
`;

const StyledLayoutReverse = styled(StyledLayout)`
    grid-template-columns:  1fr 44.8rem;

    ${respond('bp1')} {
        grid-template-columns:  1fr 42rem;
    }

    
	${respond('bp4')} {
		display: flex;
        flex-direction: column;
	}

    & > div:last-of-type {
        border-top: none;
        margin-top: 0;
    }

    & > div:first-of-type {
        border-top: .3rem solid var(--text2);
        margin-top: 1.2rem;
        margin-bottom: 2.4rem;

        ${respond('bp4')} {
            border-top: none;
            margin-bottom: 0rem;
            margin-top: 0;
        }

    }
`;

const LayoutTextImage = ({ reverse, children }) => {
	if (reverse) {
		return <StyledLayoutReverse>{children}</StyledLayoutReverse>;
	}

	return <StyledLayout>{children}</StyledLayout>;
};

export default LayoutTextImage;

import React from 'react';

const Arrow = ({ fill }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fillRule='evenodd'
			strokeLinejoin='round'
			strokeMiterlimit='2'
			clipRule='evenodd'
			viewBox='0 0 34 34'>
			<g fill={fill}>
				<path d='M25.923 20.266V6.061h8v27.862H5.216v-8h15.05L0 5.657 5.657 0l20.266 20.266z' />
			</g>
		</svg>
	);
};

export default Arrow;

import React from 'react';
import styled from '@emotion/styled';

import HeaderArrow from './HeadingArrow';

import respond from '../../styles/mediaQueries';

const HeaderContainer = styled('div')`
    display: grid; 
	grid-template-columns: 44.8rem 1fr;
	grid-gap: 2.4rem;
	
	${respond('bp1')} {
		grid-template-columns: 42rem 1fr;
	}

	${respond('bp4')} {
		display: flex;
	}
`;

const HeaderContainerReverse = styled(HeaderContainer)`
	grid-template-columns:  1fr 44.8rem;

	& header {
		grid-column: 2 / 3;
	}
	
	${respond('bp1')} {
		grid-template-columns: 1fr 42rem;
	}
	
	${respond('bp4')} {
		display: flex;
	}
`;

const LayoutTextImageHeader = ({ color, children, reverse }) => {
	if (reverse) {
		return (
			<HeaderContainerReverse>
				<HeaderArrow color={color}>{children}</HeaderArrow>
			</HeaderContainerReverse>
		);
	}
	return (
		<HeaderContainer>
			<HeaderArrow color={color}>{children}</HeaderArrow>
		</HeaderContainer>
	);
};

export default LayoutTextImageHeader;

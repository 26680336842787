import React from 'react';
import styled from '@emotion/styled';

import LayoutTextImage from '../shared/LayoutTextImage';
import LayoutTextImageHeader from '../shared/LayoutTextImageHeader';
import TextBlock from '../shared/TextBlock';

const AboutSkills = () => {
	const SkillsGrid = styled('div')`
    display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 2.8rem;

    & ul {
        list-style-type: none;
    }
`;

	return (
		<React.Fragment>
			<LayoutTextImageHeader color={'var(--text2)'}>
				Skills
			</LayoutTextImageHeader>
			<LayoutTextImage>
				<TextBlock>
					<p>
						Here is a short list of tech I’m comfortable with. If
						you need something else, just ask!
					</p>
					<div />
					<SkillsGrid>
						<div>
							<h6>Frontend</h6>
							<ul>
								<li>React</li>
								<li>Gatsby</li>
								<li>Next.js</li>
								<li>Sass</li>
								<li>Redux</li>
								<li>EJS</li>
							</ul>
						</div>
						<div>
							<h6>Backend</h6>
							<ul>
								<li>Node.js</li>
								<li>MongoDB</li>
								<li>PostgreSQL</li>
								<li>Express</li>
								<li>JWT Authentication</li>
								<li>GraphQL</li>
							</ul>
						</div>
						<div>
							<h6>Deployment & Testing</h6>
							<ul>
								<li>Jest</li>
								<li>Enzyme</li>
								<li>Github</li>
								<li>Heroku</li>
								<li>Netlify</li>
								<li>Vercel</li>
							</ul>
						</div>
						<div>
							<h6>General</h6>
							<ul>
								<li>Git</li>
								<li>Typescript</li>
								<li>Figma</li>
								<li>Affinity Designer</li>
								<li>Affinity Photo</li>
								<li>Adobe XD</li>
							</ul>
						</div>
					</SkillsGrid>
				</TextBlock>
				<div />
			</LayoutTextImage>
		</React.Fragment>
	);
};

export default AboutSkills;
